<template>
  <v-navigation-drawer
    id="core-navigation-drawer"
    v-model="drawer"
    app
    :dark="barColor !== 'rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)'"
    :expand-on-hover="expandOnHover"
    :right="$vuetify.rtl"
    mobile-breakpoint="960"
    mini-variant-width="80"
    width="260"
    v-bind="$attrs"
  >
    <v-list
      id="profileList"
      dense
      :color="pagestyles.profilebg"
    >
      <base-item-group :item="profile" />
    </v-list>
    <v-divider class="mb-1" />
    <v-list
      :key="reloadList"
      nav
      expand
    >
      <!-- Style cascading bug  -->
      <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
      <div />

      <template v-for="(item, i) in computedItems">
        <base-item-group
          v-if="item.children"
          :key="`group-${i}`"
          :item="item"
        >
          <!--  -->
        </base-item-group>

        <base-item
          v-else
          :key="`item-${i}`"
          :item="item"
        />
      </template>

      <!-- Style cascading bug  -->
      <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
      <div />
    </v-list>
  </v-navigation-drawer>
</template>
<script>
  import { mapFields } from 'vuex-map-fields'
  import customColors from '@/styles/appcolors'
  export default {
    props: {
      expandOnHover: {
        type: Boolean,
        default: false,
      },
    },
    data: function () {
      return {
        errorMessage: '',
        environment: process.env.VUE_APP_ENVIRONMENT,
        modelProcessing: false,
        reloadList: 0,
        pagestyles: {
          profilebg: customColors.ProfileDrawer.background,
        },
      }
    },
    computed: {
      ...mapFields(['barColor', 'drawer', 'currentUser', 'menuItems']),
      computedItems: function () {
        return this.menuItems.map(this.mapItem)
      },
      isAuthenticated: function () {
        return this.$msal.isAuthenticated()
      },
      userProfile () {
        return this.msal.user
      },
      avatarImage () {
        let avatarImage = 'data:image/jpeg;base64,'
        const profileImage = localStorage.getItem('ProfileImage')
        if (profileImage != null) {
          avatarImage = avatarImage + profileImage
        }
        return avatarImage
      },
      profile () {
        return {
          avatar: true,
          avatarImage: this.avatarImage,
          group: '',
          title: this.msal.user.name,
          children: [
            /*
            {
              to: 'myprofile',
              title: this.$t('my-profile'),
            },
            {
              to: 'mysettings',
              title: this.$t('my-settings'),
            },
            */
            {
              to: 'logout',
              title: this.$t('listMenuSignout'),
            },
          ],
        }
      },
    },
    created: function () {
    },
    methods: {
      mapItem (item) {
        return {
          ...item,
          children: item.children ? item.children.map(this.mapItem) : undefined,
          title: this.$t(item.title),
        }
      },
    },
  }
</script>

<style lang="sass">
  @import '~vuetify/src/styles/tools/_rtl.sass'

  #core-navigation-drawer
    &.v-navigation-drawer--mini-variant
      .v-list-item
        justify-content: flex-start !important

      .v-list-group--sub-group
        display: block !important

    .v-list-group__header.v-list-item--active:before
      opacity: .24

    .v-list-item
      &__icon--text,
      &__icon:first-child
        justify-content: center
        text-align: center
        width: 20px

        +ltr()
          margin-right: 24px
          margin-left: 12px !important

        +rtl()
          margin-left: 24px
          margin-right: 12px !important

    .v-list--dense
      .v-list-item
        &__icon--text,
        &__icon:first-child
          margin-top: 10px

    .v-list-group--sub-group
      .v-list-item
        +ltr()
          padding-left: 8px

        +rtl()
          padding-right: 8px

      .v-list-group__header
        +ltr()
          padding-right: 0

        +rtl()
          padding-right: 0

        .v-list-item__icon--text
          margin-top: 19px
          order: 0

        .v-list-group__header__prepend-icon
          order: 2

          +ltr()
            margin-right: 8px

          +rtl()
            margin-left: 8px
</style>
